import Head from 'next/head'
import environment from 'utility/environment'
import Script from 'next/script'

import isBrowser from 'utility/isBrowser'

const Meta = ({
	title = 'Home Tax Shield',
	description,
	canonical,
	appendTitle = true,
	css,
	image,
	js,
	noCrawl = false,
	// Blog Related
	category,
	updated,
	published,
}) => {
	const currentPath = isBrowser ? document.URL : ''

	let _title = title
		? `${title.substring(0, 60)}${appendTitle ? ' - Home Tax Shield' : ''}`
		: 'Home Tax Shield'
	let defaultImage = '/FacebookLogo.png'
	let desc = description
		? `${description.substring(0, 155)}`
		: 'Helping homeowners pay fair property taxes'

	return (
		<>
			<Head>
				{/* Standard */}
				<title>{_title}</title>
				<meta name='description' content={desc} />

				<meta name='robots' content='noindex' />

				{/* Google */}
				<meta property='og:type' content='website' />
				<meta name='og:title' property='og:title' content={_title || 'Home Tax Shield'} />
				<meta
					name='google-site-verification'
					content='qv5VPqLTLInrgdebbu9J65yd3H1gjfbOCfp59Fy_EDg'
				/>

				<meta name='og:description' property='og:description' content={desc} />
				<meta property='og:site_name' content='Home Tax Shield' />
				{canonical ? <meta property='og:url' content={`${canonical}`} /> : null}
				<meta property='og:image' content={`${image ? image : defaultImage}`} />
				<meta property='og:image:width' content={'850'} />
				<meta property='og:image:height' content={'443'} />
				<meta property='og:image:type' content={'image/png'} />

				{/* Twitter */}
				<meta name='twitter:card' content='summary' />
				<meta name='twitter:title' content={_title} />
				<meta name='twitter:description' content={desc} />
				<meta name='twitter:site' content='@hometaxshiled' />
				<meta name='twitter:creator' content='@hometaxshiled' />
				<meta name='twitter:image' content={`${image ? image : defaultImage}`} />

				{/* Favicons & Icons */}
				<link rel='icon' href='%PUBLIC_URL%/favicon.ico' />
				<link rel='icon' type='image/png' href='/favicon.ico' />
				<link rel='apple-touch-icon' href='/favicon.ico' />
				<link rel='manifest' href='/manifest.json' />

				{css && <link rel='stylesheet' href={`${css}`} />}
				{js && <script type='text/javascript' src={`${js}`}></script>}
				{canonical ? <link rel='canonical' href={`${canonical}`} /> : null}
				{noCrawl ? (
					<meta name='robots' content='noindex, nofollow' />
				) : (
					<meta name='robots' content='index, follow' />
				)}

				{/* Blog Releated */}
				{category && <meta name='article:section' contnet={category} />}
				{updated && <meta name='article:modified_time' contnet={updated} />}
				{published && <meta name='article:published_time' contnet={published} />}

				{/* We only have english - us */}
				<link rel='alternate' href={currentPath} hrefLang='en-us' />

				{environment.production ? (
					<>
						<script
							dangerouslySetInnerHTML={{
								__html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TGJD4TB');
          `,
							}}
						/>
					</>
				) : null}

				{/* {environment.production ? (
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TGJD4TB');
            `}
          </Script>
        ) : null} */}

				{/* Custom script tags */}
				{environment.production ? (
					<>
						<script
							dangerouslySetInnerHTML={{
								__html: `!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '647343572715405');
              fbq('track', 'PageView');`,
							}}
						/>
						<noscript
							dangerouslySetInnerHTML={{
								__html: `<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=647343572715405&ev=PageView&noscript=1" />`,
							}}
						/>
					</>
				) : null}

				{/* Facebook - second code */}
				{environment.production ? (
					<>
						<script
							dangerouslySetInnerHTML={{
								__html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1358653897669185');
            fbq('track', 'PageView');`,
							}}
						/>
						<noscript>
							<img
								height='1'
								width='1'
								style={{ display: 'none' }}
								src='https://www.facebook.com/tr?id=1358653897669185&ev=PageView&noscript=1'
							/>
						</noscript>
					</>
				) : null}

				{environment.production ? (
					<script
						type='text/javascript'
						dangerouslySetInnerHTML={{
							__html: `
        adroll_adv_id = "ZCAMUVF7VNFW5H432MTJIM"; adroll_pix_id = "RQGIIEAZ4BC33GCI2I6X54"; adroll_version = "2.0";  (function(w, d, e, o, a) { w.__adroll_loaded = true; w.adroll = w.adroll || []; w.adroll.f = [ 'setProperties', 'identify', 'track' ]; var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js"; for (a = 0; a < w.adroll.f.length; a++) { w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) { return function() { w.adroll.push([ n, arguments ]) } })(w.adroll.f[a]) }  e = d.createElement('script'); o = d.getElementsByTagName('script')[0]; e.async = 1; e.src = roundtripUrl; o.parentNode.insertBefore(e, o); })(window, document); adroll.track("pageView");
      `,
						}}
					/>
				) : null}

				{environment.production ? (
					<>
						<script
							dangerouslySetInnerHTML={{
								__html: `<script type="text/javascript">
            _linkedin_partner_id = "2586586";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          </script><script type="text/javascript">
          (function(){var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})();
          </script>
          `,
							}}
						/>
						<noscript>
							<img
								height='1'
								width='1'
								style={{ display: 'none' }}
								alt=''
								src='https://px.ads.linkedin.com/collect/?pid=2586586&fmt=gif'
							/>
						</noscript>
					</>
				) : null}
				{/* <noscript dangerouslySetInnerHTML={{__html: `
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1358653897669185&ev=PageView&noscript=1"/>
      `}}/> */}
				{/* End Facebook second code */}

				<script
					id='mcjs'
					dangerouslySetInnerHTML={{
						__html: `
        !function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/9eac8506b019c9e46f153730c/a39660a7056923b3ea44d9fe4.js");\
      `,
					}}
				/>

				{/* <!-- This site is converting visitors into subscribers and groups with OptinMonster - https://optinmonster.com --> */}
				{/* {environment.production ? 
        <script type="text/javascript" src="https://a.omappapi.com/app/js/api.min.js" data-account="97929" data-user="87372" async></script>
      : null} */}
				{/* <!-- / https://optinmonster.com --> */}
				<noscript>
					<iframe
						src='https://www.googletagmanager.com/ns.html?id=GTM-MQJCLCR'
						height='0'
						width='0'
						style={{
							display: 'none',
							visibility: 'hidden',
						}}
					></iframe>
				</noscript>

				{environment.production ? (
					<script
						dangerouslySetInnerHTML={{
							__html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:2310516,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
						}}
					/>
				) : null}

				{environment.production ? (
					<>
						<script
							dangerouslySetInnerHTML={{
								__html: `
            (function(win, doc, sdk_url){
            if(win.ndp) return;
            var tr=win.ndp=function(){
              tr.handleRequest? tr.handleRequest.apply(tr, arguments):tr.queue.push(arguments);
            };
            tr.queue = [];
            var s='script';
            var new_script_section=doc.createElement(s);
            new_script_section.async=!0;
            new_script_section.src=sdk_url;
            var insert_pos=doc.getElementsByTagName(s)[0];
            insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
            })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
          
            ndp('init','89e79f85-ba6d-49d3-ab6d-e8bb7a9e5c86', {})
            ndp('track','PAGE_VIEW');
          `,
							}}
						></script>
						<noscript>
							 {' '}
							<img
								height='1'
								width='1'
								style={{ display: 'none' }}
								src='https://flask.nextdoor.com/pixel?pid=89e79f85-ba6d-49d3-ab6d-e8bb7a9e5c86&ev=PAGE_VIEW&noscript=1'
							/>
						</noscript>
					</>
				) : null}

				<meta name='facebook-domain-verification' content='3as2r5k1a26ps5fsjh5wcoi3pfer3o' />

				{/* Other tags */}
				<meta name='viewport' content='initial-scale=1.0, width=device-width' key='viewport' />
				{/* Disables caching */}
				<meta httpEquiv='Pragma' content='no-cache' />
				<meta httpEquiv='Expires' content='0' />
				<meta charSet='UTF-8' />
				<link
					href='https://fonts.googleapis.com/css?family=Open+Sans|Poppins|Raleway&display=swap'
					rel='stylesheet'
				/>
			</Head>
		</>
	)
}
export default Meta
